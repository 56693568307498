@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.rdg.fill-grid {
  --rdg-border-color: #e5e7eb;
  --rdg-header-background-color: #f9fafb;
  --rdg-row-hover-background-color: #f3f4f6;
  --rdg-background-color: #ffffff;
  
  height: 100%;
  border: 1px solid var(--rdg-border-color);
  overflow: auto;
}

.rdg.fill-grid .rdg-cell {
  /* 单元格样式 */
  background-color: var(--rdg-background-color);
  border-right: 1px solid var(--rdg-border-color);
  border-bottom: 1px solid var(--rdg-border-color);
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.rdg.fill-grid .rdg-header-row {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--rdg-header-background-color);
}

.rdg.fill-grid .rdg-header-row .rdg-cell {
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: #f9fafb;
}

.rdg.fill-grid .rdg-row:hover .rdg-cell {
  /* 行悬停效果 */
  background-color: var(--rdg-row-hover-background-color);
}

/* 确保最后一列不会有多余的右边框 */
.rdg.fill-grid .rdg-cell:last-child {
  border-right: none;
}

.custom-scrollbar {
  /* 设置滚动条宽度 */
  scrollbar-width: thin;  /* Firefox */
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
}

/* 专门针对 DataGrid 的滚动条样式 */
.rdg.fill-grid {
  --rdg-scrollbar-size: 8px;
  
  ::-webkit-scrollbar {
    width: var(--rdg-scrollbar-size);
    height: var(--rdg-scrollbar-size);
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}


/* 选中行的样式 */
.rdg-row-selected {
  background-color: rgb(203, 213, 225) !important;
}

/* 选中行的单元格样式 */
.rdg-row-selected .rdg-cell {
  background-color: rgb(203, 213, 225) !important;
}

/* 选中行悬停时的样式 */
.rdg-row-selected:hover .rdg-cell {
  background-color: rgb(148, 163, 184) !important;
}

/* 确保选中状态优先级高于普通悬停状态 */
.rdg.fill-grid .rdg-row-selected .rdg-cell {
  background-color: rgb(203, 213, 225) !important;
}

.rdg.fill-grid .rdg-row-selected:hover .rdg-cell {
  background-color: rgb(148, 163, 184) !important;
}
